import React from 'react';
import { motion } from 'framer-motion';
import { Image, Check, Zap, Star, Clock, Headphones, Sparkles } from 'lucide-react'; // Import icons

// Add a mapping for feature icons
const featureIcons = {
    "Unlimited Folders": Zap,
    "Unlimited Custom Prompts": Star,
    "Unlimited Favorites": Star,
    "Unlimited Rich Text Notes": Sparkles,
    "Unlimited Search": Zap,
    "Priority Support": Headphones,
    "Early Access to New Features": Clock,
    "Unlimited Image Organization": Image,
    // For Basic plan features
    "2 Folders": Zap,
    "2 Custom Prompts": Star,
    "2 Favorites": Star,
    "1 Rich Text Note": Sparkles,
    "Basic Support": Headphones,
};

const plans = [
    {
        name: "Basic",
        price: 0,
        features: [
            "2 Folders per AI",
            "2 Custom Prompts per AI",
            "2 Favorites per AI",
            "1 Rich Text Note per AI",
            "Unlimited Search",
            "Unlimited Image Organization",
            "Basic Support"
        ]
    },
    {
        name: "Pro",
        price: 5,
        link: "https://ring-gpt.lemonsqueezy.com/buy/c628acc7-739d-43a5-9cd0-f99ec1bb16c3",
        features: [
            "Unlimited Folders",
            "Unlimited Custom Prompts",
            "Unlimited Favorites",
            "Unlimited Rich Text Notes",
            "Unlimited Search",
            "Unlimited Image Organization",
            "Priority Support",
            "Early Access to New Features"
        ],
        isPopular: true
    },
    {
        name: "Elite",
        price: 99,
        link: "https://ring-gpt.lemonsqueezy.com/buy/f319dfb2-8ce2-4cf5-9605-d9dc3c0b16c6",
        features: [
            "Unlimited Folders",
            "Unlimited Custom Prompts",
            "Unlimited Favorites",
            "Unlimited Rich Text Notes",
            "Unlimited Search",
            "Unlimited Image Organization",
            "Priority Support",
            "Early Access to New Features"
        ]
    }
];

const PricingCard = ({ name, price, features, isPopular, link }) => {
    const CardWrapper = link ? motion.a : motion.div;
    const cardProps = link ? {
        href: link,
        target: "_blank",
        rel: "noopener noreferrer"
    } : {};

    return (
        <CardWrapper
            {...cardProps}
            className={`bg-gray-800/80 backdrop-blur-sm p-8 rounded-xl relative ${isPopular ? 'border-2 border-gradient' : ''} ${link ? 'cursor-pointer hover:-translate-y-2 transition-transform duration-300' : ''}`}
        >
            {isPopular && (
                <span className="bg-primary-gradient text-white px-3 py-1 rounded-full text-sm absolute -top-3 right-4">
                    Most Popular
                </span>
            )}
            <h3 className="text-2xl font-bold text-gradient mb-4">{name}</h3>
            <div className="text-4xl font-bold text-gradient mb-6">
                {price === 0 ? 'Free' : `$${price}`}
                {price !== 99 && price !== 0 && '/month'}
                {price === 99 && ' Lifetime'}
            </div>
            <ul className="space-y-4">
                {features.map((feature, index) => {
                    const FeatureIcon = featureIcons[feature] || Check;
                    return (
                        <li key={index} className="flex items-center text-gray-300 group">
                            <span className="flex-shrink-0 w-5 h-5 mr-3 text-violet-600">
                                <FeatureIcon className="w-full h-full transition-transform duration-200 group-hover:scale-110" />
                            </span>
                            <span className="text-lg">{feature}</span>
                        </li>
                    );
                })}
            </ul>
        </CardWrapper>
    );
};

const Pricing = () => {
    return (
        <section id="pricing" className="relative py-20 bg-black">
            <div className="absolute inset-0 bg-grid-pattern opacity-10" />
            <div className="container mx-auto px-6 relative z-10 text-center">
                    <h2 className="text-4xl font-bold text-gradient mb-4">
                        Simple, Transparent Pricing
                    </h2>
                    <p className="text-gray-400 max-w-2xl mx-auto mb-4">
                        Choose the plan that best fits your needs
                    </p>
                    <p className="text-white max-w-2xl mx-auto text-xl font-medium bg-primary-gradient rounded-lg px-4 py-2 inline-block mb-6">
                        No Signup Needed, Use same email during checkout that you use for ChatGPT | Claude | Perplexity
                    </p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto text-left">
                    {plans.map((plan, index) => (
                        <PricingCard key={index} {...plan} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Pricing;