// src/pages/Privacy.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';

const Privacy = () => {
    return (
        <div className="bg-black min-h-screen">
            <div className="container mx-auto px-6 py-24 prose prose-invert max-w-4xl">
                <h1 className="text-4xl font-bold text-gradient mb-8">Privacy Policy</h1>
                <div className="space-y-8 text-gray-300">
                    <section>
                        <h2 className="text-2xl font-semibold text-gradient mb-4">1. Information We Collect</h2>
                        <h3 className="text-xl font-semibold text-white mt-4">Automatically Collected Information</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Browser type and version</li>
                            <li>Extension usage statistics</li>
                        </ul>

                        <h3 className="text-xl font-semibold text-white">User-Provided Information</h3>
                        <ul className="list-disc pl-6">
                            <li>Email address (for paid subscriptions)</li>
                            <li>Payment information (processed by Lemonsqueezy)</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">2. Chat Data Privacy</h2>
                        <ul className="list-disc pl-6">
                            <li>Chat contents remain in your browser</li>
                            <li>We cannot access your conversations</li>
                            <li>No chat data is stored on our servers</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">3. How We Use Information</h2>
                        <p>We use collected information to:</p>
                        <ul className="list-disc pl-6">
                            <li>Provide and maintain the Extension</li>
                            <li>Process payments</li>
                            <li>Send important updates</li>
                            <li>Improve the service</li>
                            <li>Respond to support requests</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">4. Third-Party Services</h2>
                        <p>We use these services:</p>
                        <ul className="list-disc pl-6">
                            <li>Lemonsqueezy for payments</li>
                            <li>Chrome Storage API for data</li>
                            <li>Analytics for usage statistics</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">5. Data Retention</h2>
                        <ul className="list-disc pl-6">
                            <li>Local data remains until you uninstall</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">6. Children's Privacy</h2>
                        <p>We do not knowingly collect data from users under 13.</p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">7. Changes to Policy</h2>
                        <p>We will notify users of privacy policy changes via email.</p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">8. Contact</h2>
                        <p>Privacy questions: <a href="mailto:admin@ring-gpt.com" className="text-gradient">admin@ring-gpt.com</a></p>
                    </section>

                    <p className="text-sm mt-8">Last updated: December 11, 2024</p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;