import React from 'react';
import { motion } from 'framer-motion';
import TypingEffect from './TypingEffect';
import { Chrome, Flame } from 'lucide-react';

const Hero = () => {
    return (
        <div className="relative pt-32 pb-16 bg-gradient-to-br from-slate-800 via-purple-950 to-black">
            <div className="absolute inset-0 bg-grid-pattern opacity-10" />
            <div className="container mx-auto px-6">
                {/* Main flex container - vertical on mobile, horizontal on lg */}
                <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
                    {/* Left side content */}
                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.6}}
                        className="w-full lg:w-[45%]"
                    >
                        <h1 className="text-4xl md:text-6xl font-bold text-white mb-4">
                            One <span className="text-gradient">Extension</span> To <span className="text-gradient">Rule</span> Them All
                        </h1>
                        <div className="text-xl md:text-2xl text-gray-300 mb-8 mt-8">
                            Effortlessly Organize And Search Your Conversations Across
                        </div>
                        <div className="text-xl md:text-2xl text-gray-300 mb-8">
                            <TypingEffect texts={['ChatGPT - Claude.AI - Perplexity.AI']}/>
                        </div>
                        <div className="flex flex-col md:flex-row gap-4 mb-8 text-xl font-semibold">
                            <motion.a
                                href="https://chromewebstore.google.com/detail/ringgpt-manage-chatgpt-cl/cikjldfjpiffkjmcnhfiknpipknijpep"
                                target="_blank"
                                rel="noopener noreferrer"
                                whileHover={{
                                    scale: 1.05,
                                    boxShadow: "0 0 20px rgba(168, 85, 247, 0.4)"
                                }}
                                whileTap={{scale: 0.95}}
                                className="group relative px-8 py-3 btn-gradient text-white rounded-lg transition-all duration-300 inline-flex items-center justify-center gap-2 overflow-hidden"
                            >
                                <Chrome className="w-5 h-5 relative z-10 transition-transform group-hover:scale-110 duration-300"/>
                                <span className="relative z-10">Chrome Extension</span>
                            </motion.a>
                            <motion.button
                                whileHover={{scale: 1.05}}
                                whileTap={{scale: 0.95}}
                                className="px-8 py-3 btn-gradient text-white rounded-lg hover:bg-green-600 transition-colors inline-flex items-center justify-center gap-2"
                            >
                                <Flame className="w-5 h-5"/>
                                FireFox - Coming Soon
                            </motion.button>
                        </div>
                        <div className="text-2xl text-white mb-4">
                            &#x2714; No Signup Needed<br/>
                            &#x2714; FREE And Paid Versions<br/>
                            &#x2714; Your data is stored locally on your browser
                        </div>
                    </motion.div>

                    {/* Right side images */}
                    <motion.div
                        initial={{opacity: 0, x: 20}}
                        animate={{opacity: 1, x: 0}}
                        transition={{duration: 0.8}}
                        className="relative w-full lg:w-[55%] h-[500px] lg:h-[600px]"
                    >
                        {/* Images container */}
                        <div className="relative w-full h-full">
                            {/* First image */}
                            <motion.div
                                initial={{ rotateY: -15, x: 0 }}
                                animate={{ rotateY: -15, x: 0 }}
                                className="absolute left-0 lg:left-0 top-0 w-48 lg:w-64 h-[350px] lg:h-[500px] rounded-xl overflow-hidden shadow-2xl"
                                style={{
                                    boxShadow: '0 0 20px rgba(147, 51, 234, 0.3)',
                                    transform: 'rotateY(-15deg) translateZ(20px)'
                                }}
                            >
                                <img
                                    src="/images/chatgpt.png"
                                    alt="Feature preview 1"
                                    className="w-full h-full object-cover rounded-xl border border-white/50"
                                />
                            </motion.div>

                            {/* Middle image */}
                            <motion.div
                                initial={{ rotateY: 0, x: 0 }}
                                animate={{ rotateY: 0, x: 0 }}
                                className="absolute left-1/4 lg:left-1/4 top-12 w-48 lg:w-64 h-[350px] lg:h-[500px] rounded-xl overflow-hidden shadow-2xl z-20"
                                style={{
                                    boxShadow: '0 0 25px rgba(99, 102, 241, 0.4)',
                                    transform: 'rotateY(0deg) translateZ(40px)'
                                }}
                            >
                                <img
                                    src="/images/claude.png"
                                    alt="Feature preview 2"
                                    className="w-full h-full object-cover rounded-xl border border-white/50"
                                />
                            </motion.div>

                            {/* Last image */}
                            <motion.div
                                initial={{ rotateY: 15, x: 0 }}
                                animate={{ rotateY: 15, x: 0 }}
                                className="absolute left-2/4 lg:left-2/4 top-24 w-48 lg:w-64 h-[350px] lg:h-[500px] rounded-xl overflow-hidden shadow-2xl z-40"
                                style={{
                                    boxShadow: '0 0 20px rgba(168, 85, 247, 0.3)',
                                    transform: 'rotateY(15deg) translateZ(20px)'
                                }}
                            >
                                <img
                                    src="/images/perp.png"
                                    alt="Feature preview 3"
                                    className="w-full h-full object-cover rounded-xl border border-white/50"
                                />
                            </motion.div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Hero;