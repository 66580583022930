// src/components/Features.jsx
import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Maximize2, X } from 'lucide-react';

const VideoModal = ({ isOpen, onClose, videoSrc }) => {
    if (!isOpen) return null;

    return (
        <AnimatePresence>
            <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center">
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    className="w-3/4 relative bg-gray-800 rounded-xl"
                >
                    <div className="aspect-[16/9] relative rounded-xl overflow-hidden">
                        <video
                            className="w-full h-full object-contain bg-black"
                            autoPlay
                            loop
                            muted
                            playsInline
                            controls
                        >
                            <source src={videoSrc} type="video/mp4" />
                        </video>
                        <button
                            onClick={onClose}
                            className="absolute top-4 right-4 bg-purple-900 backdrop-blur-sm rounded-lg border border-gray-700/50 hover:bg-gray-900/75 transition-colors"
                        >
                            <X className="h-8 w-8 text-white " />
                        </button>
                    </div>
                </motion.div>
            </div>
        </AnimatePresence>
    );
};

const features = [
    {
        title: "Lightning Search",
        description: "Quickly search through your chat history with intuitive organization. Find any conversation or response in milliseconds with our high-speed search system.",
        location: "/video/Search.mp4"
    },
    {
        title: "Folder Management",
        description: "Organize your chats into custom folders for easy access. Create a structured workspace that matches your workflow and never lose track of important conversations.",
        location: "/video/Folder.mp4"
    },
    {
        title: "Favorites",
        description: "Save your most important chats for quick reference. Mark conversations as favorites to build your personal knowledge base of AI interactions.",
        location: "/video/Favs.mp4"
    },
    {
        title: "Prompt Management",
        description: "Manage and organize your AI prompts efficiently. Create, edit, and structure your prompts for better productivity and seamless access.",
        location: "/video/Prompts.mp4"
    },
    {
        title: "Rich Notes Editing",
        description: "Enhance your workflow with our rich text editor for managing notes. Organize your thoughts, save detailed conversations, and enjoy the flexibility of robust text editing tools.",
        location: "/video/Notes.mp4"
    },
    {
        title: "Image Management",
        description: "See all your generated images in one place, complete with the actual prompt used by ChatGPT. Easily browse, review, and manage your visual creations.",
        location: "/video/Images.mp4"
    }
];

const FeatureCard = ({ title, description, location }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const videoRef = useRef(null);

    const handleExpand = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="bg-gray-800 rounded-3xl p-8 flex flex-col space-y-6"
            >
                <h3 className="text-2xl font-semibold text-gradient">
                    {title}
                </h3>
                <p className="text-white/70 text-xl leading-relaxed">
                    {description}
                </p>
                <div className="bg-gradient-to-br from-blue-400/10 via-purple-400/10 to-orange-400/10 rounded-2xl">
                    <div className="relative aspect-[16/9] bg-black rounded-xl overflow-hidden">
                        <video
                            ref={videoRef}
                            className="absolute inset-0 w-full h-full object-contain"
                            autoPlay
                            loop
                            muted
                            playsInline
                            controls={false}
                        >
                            <source src={location} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>

                        {/* Expand button */}
                        <button
                            onClick={handleExpand}
                            className="absolute bottom-4 right-4 p-2 bg-gray-900/50 backdrop-blur-sm rounded-lg border border-gray-700/50 hover:bg-gray-900/75 transition-colors"
                        >
                            <Maximize2 className="h-4 w-4 text-white" />
                        </button>
                    </div>
                </div>
            </motion.div>
            <VideoModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                videoSrc={location}
            />
        </>
    );
};

const Features = () => {
    return (
        <section id="features" className="relative py-24 bg-black">
            {/* Grid Pattern Background */}
            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `
                        linear-gradient(#232731 1px, transparent 1px),
                        linear-gradient(to right, #232731 1px, transparent 1px)
                    `,
                    backgroundSize: '50px 50px'
                }}
            />

            <div className="container mx-auto px-6 relative z-10">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    whileInView={{opacity: 1, y: 0}}
                    viewport={{once: true}}
                    className="text-center mb-20"
                >
                    <div className="flex items-center justify-center gap-4 mb-6">
                        <h1 className="text-4xl md:text-5xl font-bold text-white">
                            Your Time
                        </h1>
                        <img
                            src="/images/ring_transp.gif"
                            width={120}
                            height={120}
                            alt="RingGPT Logo"
                            className="object-contain"
                        />
                        <h1 className="text-4xl md:text-5xl font-bold text-white">
                             Is Precious
                        </h1>
                    </div>
                    <p className="text-white/70 text-2xl max-w-3xl mx-auto">
                        Stop wasting time searching through endless chat histories. Experience the power of organized AI
                        conversations with RingGPT.
                    </p>
                </motion.div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
                    {features.map((feature, index) => (
                        <FeatureCard key={index} {...feature} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;