// src/pages/Terms.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';

const Terms = () => {
    return (
        <div className="bg-black min-h-screen">
            <div className="container mx-auto px-6 py-24 prose prose-invert max-w-4xl">
                <h1 className="text-4xl font-bold text-gradient mb-8">Terms of Service</h1>
                <div className="space-y-8 text-gray-300">
                    <section>
                        <h2 className="text-2xl font-semibold text-gradient mb-4">1. Acceptance of Terms</h2>
                        <p>
                            By installing and using RingGPT ("the Extension"), you agree to these Terms of Service.
                            If you disagree with any part, please do not use the Extension.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">2. Service Description</h2>
                        <p>
                            RingGPT is a Chrome extension that helps users organize and manage their conversations
                            with AI platforms including ChatGPT Claude.ai and Perplexity.ai
                        </p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">3. User Obligations</h2>
                        <p>You agree to:</p>
                        <ul className="list-disc pl-6">
                            <li>Use the Extension only for lawful purposes</li>
                            <li>Not attempt to reverse engineer the Extension</li>
                            <li>Not use the Extension to collect unauthorized data</li>
                            <li>Maintain the security of your account</li>
                            <li>Not share your subscription with others</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">4. Subscription and Payments</h2>
                        <ul className="list-disc pl-6">
                            <li>Free tier features are provided without charge</li>
                            <li>Pro and Elite subscriptions are billed according to selected plans</li>
                            <li>All purchases are final and non-refundable</li>
                            <li>We reserve the right to modify pricing with notice</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">5. Data Storage and Privacy</h2>
                        <ul className="list-disc pl-6">
                            <li>The Extension stores chat data locally in your browser</li>
                            <li>We do not store your chat contents</li>
                            <li>See our <Link to="/privacy" className="text-gradient">Privacy Policy</Link> for detailed information</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">6. Intellectual Property</h2>
                        <ul className="list-disc pl-6">
                            <li>The Extension and all related materials are our intellectual property</li>
                            <li>You receive a limited license to use the Extension</li>
                            <li>You may not copy, modify, or distribute the Extension</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">7. Limitation of Liability</h2>
                        <p>We provide the Extension "as is" without warranties. We are not liable for:</p>
                        <ul className="list-disc pl-6">
                            <li>Service interruptions</li>
                            <li>Data loss</li>
                            <li>Damages arising from Extension use</li>
                            <li>Third-party service changes</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">8. Termination</h2>
                        <p>We may terminate your access if you:</p>
                        <ul className="list-disc pl-6">
                            <li>Violate these terms</li>
                            <li>Abuse the service</li>
                            <li>Engage in fraudulent activity</li>
                            <li>Fail to pay subscription fees</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">9. Changes to Terms</h2>
                        <p>We may update these terms with notice. Continued use constitutes acceptance of changes.</p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold text-gradient">10. Contact</h2>
                        <p>For questions about these terms, contact <a href="mailto:admin@ring-gpt.com" className="text-gradient">admin@ring-gpt.com</a></p>
                    </section>

                    <p className="text-sm mt-8">Last updated: December 11, 2024</p>
                </div>
            </div>
        </div>
    );
};

export default Terms;